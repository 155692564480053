import { Component, Input, OnInit } from "@angular/core";

export interface Pagination {
  /**
   * Active page number
   */
  page: number;
  /**
   * Active page size
   */
  size: number;
  /**
   * Total counts for page with given size
   */
  total?: number;
}
interface DataItem {
  key: string;
  lastModified: number;
  etag: string;
  size: number;
}

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent implements OnInit {
  /**
   * List of the headers for table
   */
  @Input() headers: any[] = [
    {
      name: "Name",
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.key.localeCompare(b.key),
      sortDirections: ["ascend", "descend", null],
    },
    {
      name: "Uploaded On",
      sortOrder: "descend",
      sortFn: (a: DataItem, b: DataItem) => a.lastModified - b.lastModified,
      sortDirections: ["descend", null],
    },
    {
      name: "File Size",
      sortOrder: "descend",
      sortFn: (a: DataItem, b: DataItem) => a.size - b.size,
      sortDirections: ["ascend", "descend", null],
    },
  ];
  /**
   * Holds the value for file name to be displayed
   */
  @Input() user?: string;
  /**
   * Default pagination value
   */
  readonly pagination: Pagination = { page: 1, size: 10 };
  /**
   * List of page sizes supported
   */
  readonly supportedPageSizes = [10, 25, 50];
  /**
   * Stores the values for row data
   */
  @Input() rows: any[] = [];
  /**
   * Holds the value for total count of the records
   */
  totalCount?: number;
  /**
   * Indicates that table is loading data
   */
  isLoading?: boolean;
  searchValue = "";
  visible = false;

  /**
   * Constructor for {@link DataTableComponent}
   */
  constructor() {}

  ngOnInit(): void {
    this.getPageData();
  }

  reset(): void {
    this.searchValue = "";
    this.search();
  }

  search(): void {
    this.visible = false;
    this.rows = this.rows?.filter(
      (item: any) => item.name.indexOf(this.searchValue) !== -1
    );
  }

  /**
   * Method to get paginated data
   * @param requestBody Contains the filename, page and size
   */
  getPageData(): void {
    //this.isLoading = true;
    this.totalCount = this.rows?.length;
  }
}
