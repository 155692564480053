import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentCardComponent } from "./components/document-card/document-card.component";
import { AntdModule } from "../Antd.module";
import { SafePipe } from "./pipes/safe.pipe";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";

@NgModule({
  declarations: [DocumentCardComponent, SafePipe, UnauthorizedComponent],
  imports: [CommonModule, AntdModule],
  exports: [DocumentCardComponent, UnauthorizedComponent],
})
export class SharedModule {}
