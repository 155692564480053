import { Component } from '@angular/core';
import { Amplify, Storage } from 'aws-amplify';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { FormControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'nfl-dcrportal-fe-repo';
  list?: any;
  currentUser: any;
  code?: UntypedFormControl = new FormControl('');
  cognitoUser: any;

  constructor(private http: HttpClient) {
    //TODO: this needs to be removed. And components needs to be cleaned up
    // This is to trigger the pipeline
  }
}
