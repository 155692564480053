import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-resource-hub",
  templateUrl: "./resource-hub.component.html",
  styleUrls: ["./resource-hub.component.scss"],
})
export class ResourceHubComponent {
  /**
   * Title/banner text
   */
  bannerText: string = "Resource Hub";
  /**
   * Description text for data prep tool
   */
  dataPrepContent: string =
    "Leverage intuitive data preparation tool, equipped with built-in integrity checks and hashing functionalities for generating ready-to-share data tailored for data clean room. The tool can be operated locally to minimize the risk of sensitive data exposure while accomodating various file formats for seamless processing.";
  /**
   * Description text for data prep tool user guide
   */
  dataPrepGuideContent: string =
    "In this guide, we’ll be covering the prerequisites for setting up the NFL Data Clean Room (DCR) Data Preparation tool, including the necessary packages, installation steps, and running the application on Windows and Mac systems.";
  /**
   * Description text for S3 file exporter tool
   */
  s3ToolContent: string =
    "Utilize S3 exporter tool to seamlessly transfer processed data from your web application to an S3 bucket with one-click operation, eliminating  the necessity for external software installations. This feature empowers users to effortlessly share multiple files to a designated bucket  while also providing the capability to conveniently access previously exported files for historical reference.";
  /**
   * Description text for S3 file exporter tool user guide
   */
  s3ToolUserGuideContent: string =
    "In this guide, we’ll cover the procedures for transferring files using the NFL Data Clean Room (DCR) Data Transfer tool, including file uploads to designated S3 buckets and viewing your file upload history.";
  /**
   * Description text for AWS Connection user guide
   */
  awsGuideContent: string =
    "This guide provides step-by-step instructions for enabling cross-account data access between your AWS environment and the NFL Data Clean Room (DCR). It covers granting necessary permissions for AWS Glue and S3, as well as configuring encryption key access to ensure secure data sharing and querying.";
  /**
   * Description text for Snowflake Connection user guide
   */
  snowflakeGuideContent: string =
    "This guide details the steps for configuring cross-account access between your Snowflake data source and the NFL Data Clean Room (DCR) AWS account, allowing secure data access. It includes instructions for creating a new user with limited access in Snowflake, granting necessary permissions, and sharing credentials for configuration in AWS Athena.";

  /**
   * Constructor for {@link ResourceHubComponent}
   * @param router an instance of {@link Router} to use router navigation method(s)
   */
  constructor(private router: Router) {}

  /**
   * Method to navigate the route to contact us
   */
  navigateToContact(): void {
    this.router.navigate(["/contact-us"]);
  }
}
