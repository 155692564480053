<div class="file-movement">
  <div class="title">
    <h2>Export to S3</h2>
  </div>
  <div class="content">
    <app-file-upload
      [labelText]="'Select processed file to upload on S3 bucket'"
      [allowedExtensions]="['.csv']"
      (uploadResult)="uploadResult($event)"
    ></app-file-upload>
  </div>
</div>
