<div class="background">
  <div nz-row class="login-wrapper">
    <nz-card style="width: 500px; margin: auto" [nzCover]="coverTemplate" nzHoverable="false">
      <ng-container>
        <h2 class="logo-title">NFL DCR Portal Login</h2>
        <nz-alert class="margin-bottom" nzType="error" [nzMessage]="errorState.message" *ngIf="errorState"></nz-alert>

        <p nz-typography *ngIf="errorState">
          Try logging in once more. If you still encounter issues, please reach out to your admin for support
        </p>

        <button
          nz-button
          class="margin-bottom w100"
          (click)="federatedSignIn()"
          [nzType]="'primary'"
          [nzLoading]="isLoading"
        >
          Login
        </button>
      </ng-container>
    </nz-card>
  </div>
</div>

<ng-template #coverTemplate>
  <div class="logo">
    <img nz-image nzSrc="/assets/images/NFL_Shield_rgb.png" alt="NFL LOGO" />
  </div>
</ng-template>
