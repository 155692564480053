import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { LoginComponent } from "./components/login/login.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { HashedFileMovementComponent } from "./components/hashed-file-movement/hashed-file-movement.component";
import { ReportIssueComponent } from "./components/report-issue/report-issue.component";
import { ResendCodeComponent } from "./components/resend-code/resend-code.component";
import { AppConfigService } from "./services/app-config.service";
import { AntdModule } from "./Antd.module";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { FileListComponent } from "./components/file-list/file-list.component";
import { DateTimePipe } from "./shared/pipes/date-time.pipe";
import { LayoutComponent } from "./shared/components/layout/layout.component";
import { FileSizePipe } from "./shared/pipes/file-size.pipe";
import { AuthService } from "./services/auth.service";
import { OuterHeaderComponent } from "./components/outer-header/outer-header.component";
import { OuterFooterComponent } from "./components/outer-footer/outer-footer.component";
import { HomeComponent } from "./components/home/home.component";
import { ExternalLayoutComponent } from "./components/external-layout/external-layout.component";
import { SolutionCenterComponent } from "./components/solution-center/solution-center.component";
import { SharedModule } from "./shared/shared.module";
import { ResourceHubComponent } from "./components/resource-hub/resource-hub.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";

registerLocaleData(en);

/**
 * Creates the app-config.json file with the correct environment variables at runtime
 */
const appConfigInit = (
  appConfig: AppConfigService,
  authService: AuthService
) => {
  return () => {
    return appConfig.loadAppConfig(authService);
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WelcomeComponent,
    HeaderComponent,
    FooterComponent,
    FileUploadComponent,
    HashedFileMovementComponent,
    ReportIssueComponent,
    ResendCodeComponent,
    DataTableComponent,
    FileListComponent,
    DateTimePipe,
    LayoutComponent,
    FileSizePipe,
    OuterHeaderComponent,
    OuterFooterComponent,
    HomeComponent,
    ExternalLayoutComponent,
    SolutionCenterComponent,
    ResourceHubComponent,
    ContactUsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AntdModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigInit,
      multi: true,
      deps: [AppConfigService, AuthService],
    },
  ],
  bootstrap: [AppComponent],
  exports: [DateTimePipe],
})
export class AppModule {}
