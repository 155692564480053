<!-- Banner -->
<div class="banner">
  <div class="overlay"></div>
  <div class="banner-content">
    <h1>{{ bannerText }}</h1>
  </div>
</div>

<!-- Key Features  -->
<div nz-row class="key-features">
  <h2 nz-typography>Data Collaboration Platform Features</h2>
  <div nz-row>
    <div
      nz-col
      *ngFor="let feature of keyFeature; index as i; first as isFirst"
      [nzXs]="
        isFirst || i == 2 || i == 4
          ? { span: 11, offset: 0 }
          : { span: 11, offset: 2 }
      "
      [nzLg]="
        isFirst || i == 3 ? { span: 6, offset: 1 } : { span: 6, offset: 2 }
      "
      class="margin-top2"
    >
      <nz-card nzHoverable [nzCover]="feature1">
        <nz-card-meta
          [nzTitle]="feature.title"
          [nzDescription]="feature.description"
        ></nz-card-meta>
      </nz-card>
      <ng-template #feature1>
        <img
          alt="example"
          [src]="feature.imagePath"
          style="height: auto; width: 100%"
        />
      </ng-template>
    </div>
  </div>
</div>

<!-- Details -->
<div nz-row class="feature-details">
  <h2 nz-typography>Capabilities of NFL Data Clean Room platform</h2>
  <div
    nz-row
    class="margin-top2"
    *ngFor="
      let item of featureDetails;
      index as i;
      first as isFirst;
      last as isLast
    "
  >
    <div
      nz-col
      [nzSpan]="isFirst || isLast ? 8 : 12"
      [nzOffset]="isFirst || isLast ? 1 : 1"
      [ngClass]="{ 'text-right': isFirst || isLast }"
      class="margin-top2"
    >
      <ng-container
        [ngTemplateOutlet]="isFirst || isLast ? image : details"
        [ngTemplateOutletContext]="{ item: item }"
      >
      </ng-container>
    </div>
    <div
      nz-col
      [nzSpan]="isFirst || isLast ? 12 : 8"
      [nzOffset]="isFirst || isLast ? 1 : 1"
    >
      <ng-container
        [ngTemplateOutlet]="isFirst || isLast ? details : image"
        [ngTemplateOutletContext]="{ item: item }"
      >
      </ng-container>
    </div>
  </div>
</div>

<!-- FAQ's -->
<div nz-row class="faq">
  <div id="question">
    <h2 nz-typography>FAQ's</h2>
    <nz-collapse nzAccordion class="margin-top2">
      <nz-collapse-panel
        #p
        *ngFor="let panel of faqs; let isFirst = first"
        [nzHeader]="panel.name"
        [nzActive]="panel.active"
        [nzExpandedIcon]="panel.icon"
        [ngStyle]="panel?.customStyle"
      >
        <p>{{ panel?.content }}</p>
        <ng-template #expandedIcon let-active>
          {{ active }}
          <span
            nz-icon
            nzType="caret-right"
            class="ant-collapse-arrow"
            [nzRotate]="p.nzActive ? 90 : -90"
          ></span>
        </ng-template>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</div>

<!-- Image Template -->
<ng-template let-item="item" #image>
  <div
    class="bg-image-cover"
    [ngStyle]="{
      backgroundImage: 'url(' + item.imagePath + ')'
    }"
  ></div>
</ng-template>

<!-- details template -->
<ng-template let-item="item" #details>
  <h3 nz-typography>{{ item.title }}</h3>
  <p nz-typography>
    {{ item.description }}
  </p>
  <ul>
    <li *ngFor="let list of item.content; index as j">
      <p nz-typography>
        <span style="font-weight: 900; font-size: 18px; color: #013369">
          {{ list.title }}
        </span>
        {{ list.desc }}
        <a
          *ngIf="list?.link"
          [routerLink]="list.link"
          [fragment]="list?.sectionLink"
          >{{ list.linkText }}
        </a>
      </p>
    </li>
  </ul>
</ng-template>
