<div class="resend-code-link">
  <span> Didn't receive the code yet? </span>
  <button
    nz-button
    nzType="link"
    [disabled]="linkDisabled"
    (click)="resendCode()"
  >
    Resend Code
  </button>
  <span *ngIf="timerValue > 0">{{ timer$ | async }}</span>
</div>
