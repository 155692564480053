import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  /**
   * Constructor for {@link UnauthorizedComponent}
   * @param router an instance of {@link Router} to use navigation methods
   */
  constructor(private router: Router) {}

  /**
   * Method to navigate the route to website home page
   */
  returnToHome(): void {
    this.router.navigate(['/']);
  }
}
