import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from 'aws-amplify';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';

export interface File {
  /**
   * Unique id for the file
   */
  eTag: string;
  /**
   * Name of the file
   */
  key: string;
  /**
   * Last modified time of the file
   */
  lastModified: string;
  /**
   * Size of the given file
   */
  size: number;
}

export interface FileList {
  /**
   * List of the files
   */
  list: File[];
}

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnInit {
  /**
   * Holds the user details stored in cognito
   */
  userDetails: any;
  /**
   * List of files uploaded in s3 for logged in user
   */
  fileList: any;
  /**
   * Indicates that data is loading
   */
  isLoading?: boolean;
  /**
   * Indicates if user is restricted user
   */
  restrictedUser?: boolean;

  /**
   * Constructor for {@link FileListComponent}
   * @param authService an instance of {@link AuthService} used to get the logged in user info
   * @param nzMessageService an instance of {@link NzMessageService} used to display user friendly message to user
   * @param router an instance of {@link Router} to use navigation methods
   * @param activatedRoute an instance of {@link ActivatedRoute} to get the active parent of the route
   */
  constructor(
    private authService: AuthService,
    private nzMessageService: NzMessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userDetails = this.authService.getUserInfo();

    this.restrictedUser = this.userDetails?.attributes['custom:isRestricted'] === 'true';

    if (this.restrictedUser) {
      this.router.navigate(['unauthorized'], { relativeTo: this.activatedRoute.parent });
      return;
    }

    Storage.configure({
      AWSS3: {
        bucket: this.userDetails.attributes['custom:bucketName']?.trim(),
        region: 'us-east-1',
      },
    });

    this.getFileList();
  }

  getFileList() {
    Storage.list('', { level: 'private' }) // for listing ALL files without prefix, pass '' instead
      .then(({ results }) => {
        //TODO: Add type of above results
        this.fileList = results;
        this.isLoading = false;
      })
      .catch((err) => {
        this.nzMessageService.error('You are not authorized to perform this action. Please contact admin');
      });
  }
}
