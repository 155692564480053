import { Pipe, PipeTransform } from "@angular/core";
import * as dayjs from "dayjs";

//import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

@Pipe({
  name: "dateTime",
})
export class DateTimePipe implements PipeTransform {
  /**
   * returns the formatted date with abbreviated timezone
   * @param value value is the date need to be formatted
   * @param includeTime flag to determine if the time should be included in the transformed value
   */
  transform(value: string, includeTime: boolean = true): string {
    // @ts-ignore
    if (dayjs(value).isValid()) {
      /**
       * Use the below code for dynamic timezone according to user's local time.
       * const timezone = dayjs.tz.guess();
       */
      const timezone = "America/New_York";
      const format = `DD-MM-YYYY${includeTime ? " hh:mm:ss A z" : ""}`;
      /**
       * dayjs is not supporting the correct abbreviation format for some timezones.
       * https://github.com/iamkun/dayjs/issues/1154
       */
      // @ts-ignore
      return dayjs(value).tz(timezone).format(format);
    }
    return value;
  }
}
