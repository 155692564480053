import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-outer-footer",
  templateUrl: "./outer-footer.component.html",
  styleUrls: ["./outer-footer.component.scss"],
})
export class OuterFooterComponent {
  /**
   * Constructor for {@link OuterFooterComponent}
   * @param router an instance of {@link Router} to use router navigation method(s)
   */
  constructor(private router: Router) {}

  /**
   * Method to navigate route to contact us
   */
  navigateToContact(): void {
    this.router.navigate(["/contact-us"]);
  }
}
