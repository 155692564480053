<!-- Banner -->
<div class="banner">
  <div class="overlay"></div>
  <div class="banner-content">
    <h1>{{ bannerText }}</h1>
  </div>
</div>

<!-- Data prep Tool Section -->
<div nz-row class="dcr-prep-tool">
  <div nz-row class="margin-top2">
    <div nz-col [nzXs]="{ span: 11, offset: 1 }" [nzLg]="{ span: 7, offset: 2 }">
      <img nz-image width="100%" nzSrc="/assets/images/data-prep.jpg" [nzDisablePreview]="true" />
    </div>
    <div nz-col [nzXs]="{ span: 11, offset: 1 }" [nzLg]="{ span: 10, offset: 1 }">
      <h2 nz-typography>Data Preparation Tool</h2>
      <p nz-typography>{{ dataPrepContent }}</p>
      <button
        nz-button
        nzType="primary"
        [nzSize]="'large'"
        nzShape="round"
        class="margin-left1"
        (click)="navigateToContact()"
      >
        <span nz-icon nzType="audit" nzTheme="outline"></span>
        Request Demo
      </button>
    </div>
  </div>

  <div nz-row class="margin-top2 document" id="data=prep-guide">
    <app-document-card
      [colSpan]="20"
      [offset]="2"
      [title]="'Data Preparation User Guide'"
      [pdfUrl]="'/assets/docs/NFL Data Preparation UI Tool-User Guide.pdf'"
      [content]="dataPrepGuideContent"
    ></app-document-card>
  </div>
</div>

<!-- S3 exporter tool -->
<div nz-row class="exporter-tool">
  <div nz-row class="margin-top2">
    <div nz-col [nzXs]="{ span: 11, offset: 1 }" [nzLg]="{ span: 10, offset: 2 }">
      <h2 nz-typography>S3 Exporter Tool</h2>
      <p nz-typography>
        {{ s3ToolContent }}
      </p>
      <button nz-button nzType="primary" [nzSize]="'large'" nzShape="round" [routerLink]="['/exporter-tool']">
        <span nz-icon nzType="monitor" nzTheme="outline"></span>
        Explore
      </button>
      <button
        nz-button
        nzType="primary"
        [nzSize]="'large'"
        nzShape="round"
        class="margin-left1"
        (click)="navigateToContact()"
      >
        <span nz-icon nzType="audit" nzTheme="outline"></span>
        Request Demo
      </button>
    </div>
    <div nz-col [nzXs]="{ span: 11, offset: 1 }" [nzLg]="{ span: 8, offset: 2 }">
      <img nz-image width="100%" nzSrc="/assets/images/file-transfer.jpg" [nzDisablePreview]="true" />
    </div>
  </div>

  <div nz-row class="margin-top2 document" id="data-sharing-guide">
    <app-document-card
      [colSpan]="20"
      [offset]="2"
      [title]="'S3 Exporter Tool User Guide'"
      [pdfUrl]="'/assets/docs/NFL S3 File Exporter Tool.pdf'"
      [content]="s3ToolUserGuideContent"
    ></app-document-card>
  </div>
</div>

<!-- Other Useful resources -->
<div nz-row class="other-resources">
  <h2 nz-typography>Other Useful Resources</h2>
  <div nz-row class="docs-grid">
    <div nz-col [nzSpan]="12">
      <app-document-card
        [colSpan]="21"
        [offset]="2"
        [title]="'SnowFlake Data Connection Guide'"
        [pdfUrl]="'/assets/docs/NFL DCR Snowflake Connection - User Guide.pdf'"
        [content]="snowflakeGuideContent"
      ></app-document-card>
    </div>
    <div nz-col [nzSpan]="12">
      <app-document-card
        [colSpan]="20"
        [offset]="1"
        [title]="'AWS Data Connection Guide'"
        [pdfUrl]="'/assets/docs/AWS-connection-guide.pdf'"
        [content]="awsGuideContent"
      ></app-document-card>
    </div>
  </div>
</div>
