import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Storage } from 'aws-amplify';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  /**
   * Holds list of allowed file extensions
   */
  @Input() allowedExtensions: string[] = [];
  /**
   * Stores the text value for file upload label
   */
  @Input() labelText: string = 'Select file to upload';
  /**
   * Optional attribute to support/display hint labels
   */
  @Input() hintLabel?: string = '';
  /**
   * Indicates if component is being rendered for reporting issue
   */
  @Input() isReportIssue?: boolean;
  /**
   * Indicates user is restricted to perform operations in S3
   */
  @Input() restrictedUser?: boolean;
  /**
   * Holds the details for the current user
   */
  @Input() userDetails: any;
  /**
   * Event emitter for upload result
   */
  @Output() uploadResult: EventEmitter<any> = new EventEmitter();
  /**
   * Loader used to indicate file is loading
   */
  uploading: boolean = false;
  /**
   * List of the files that are in queue for getting uploaded
   */
  fileList: NzUploadFile[] = [];
  /**
   * Shows the upload progress of file
   */
  progressRatio: number = 0;
  /**
   * Indicates the loaded is spinning
   */
  isSpinning: boolean = false;

  /**
   * Constructor for {@link FileUploadComponent}
   * @param msg an instance of {@link NzMessageService} used to show informational messages to users
   */
  constructor(private nzMessageService: NzMessageService) {}

  ngOnInit(): void {
    this.restrictedUser = this.userDetails.attributes['custom:isRestricted'] === 'true';

    // TODO: Add the condition if bucket name exist in user attributes
    Storage.configure({
      AWSS3: {
        bucket: this.userDetails.attributes['custom:bucketName']?.trim(),
        region: 'us-east-1',
      },
    });
  }

  /**
   * Method to concat the file for upload before uploading to S3
   * @param file File to be added
   * @returns false
   */
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  /***
   * Method to upload file to S3
   */
  privateUpload(): void {
    if (!this.fileList.every((file) => this.validateFile(file))) {
      return;
    }
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.fileList.forEach((file: any) => {
      formData.append('files[]', file);
      this.uploading = true;
      this.isSpinning = true;
      Storage.put(file.name, file, {
        level: 'private',
        progressCallback: (progress: any) => {
          this.progressRatio = Math.round((progress.loaded / progress.total) * 100);
        },
        errorCallback: (err: any) => {
          this.nzMessageService.error(`Unexpected error while uploading ${err}`);
        },
      })
        .then((result) => {
          this.uploading = false;
          this.isSpinning = false;
          this.fileList = [];
          this.progressRatio = 0;
          this.uploadResult.emit('success');
          //this.nzMessageService.success("File(s) uploaded successfully.");
        })
        .catch((error) => {
          this.uploading = false;
          this.isSpinning = false;
          this.uploadResult.emit(error);
          // this.nzMessageService.error(
          //   `File(s) upload failed with error ${err}`
          // );
        });
    });
  }

  /***
   * Method to upload file to S3
   */
  publicUpload(): void {
    if (!this.fileList.every((file) => this.validateFile(file))) {
      return;
    }
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.fileList.forEach((file: any) => {
      formData.append('files[]', file);
      this.uploading = true;
      this.isSpinning = true;
      Storage.put(file.name, file, {
        level: 'public',
        progressCallback: (progress: any) => {
          this.progressRatio = (progress.loaded / progress.total) * 100;
        },
        errorCallback: (err: any) => {
          console.error('Unexpected error while uploading', err);
        },
      })
        .then((result) => {
          this.uploading = false;
          this.isSpinning = false;
          this.fileList = [];
          this.progressRatio = 0;
          this.nzMessageService.success('File(s) uploaded successfully.');
        })
        .catch((err) => {
          this.uploading = false;
          this.nzMessageService.error(`File(s) upload failed with error ${err}`);
        });
    });
  }

  /**
   * Method to validated uploaded files are from allowed file list
   * @param file File Object
   * @returns Boolean true when file is valid and false for invalid
   */
  validateFile(file: NzUploadFile): boolean {
    const validFileTypes = [...this.allowedExtensions, 'text/csv'];
    const fileExtension = file.name.split('.')[1];
    if (!validFileTypes.includes(file.type || `.${fileExtension}`)) {
      this.nzMessageService.error(`${file.name} file is not valid, please select valid CSV file`);
      setTimeout(() => (this.isSpinning = false), 200);
      return false;
    }

    return true;
  }

  handleUpload() {
    if (this.restrictedUser) {
      this.nzMessageService.error('You are not authorized to perform operations on S3 bucket. Please contact admin');
      this.fileList = [];
      return;
    }
    if (this.isReportIssue) {
      this.publicUpload();
      return;
    }
    this.privateUpload();
  }
}
