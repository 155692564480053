import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

/**
 * Email Validator used to check for valid email.
 *
 * This function ensures that the email address includes the local part, "@" symbol,
 * domain name, and a valid top-level domain (TLD) with 2 to 3 characters.
 * It will throw an error if any of these components are missing or if the TLD is invalid.
 **/

export function customEmailValidator(): ValidatorFn {
  /**
   * Regex for validating email pattern.
   *
   * This pattern ensures that the email address includes the local part, "@" symbol,
   * domain name, and a valid top-level domain (TLD) with 2 to 3 characters.
   * It will throw an error if any of these components are missing or if the TLD is invalid.
   *
   * Examples of valid email addresses based on the regex:
   *   user@example.com
   *   john.doe123@example.co.uk
   *   some_email@my-website.net
   *
   * Examples of invalid email addresses based on the regex:
   *   user@.com (Missing characters before "@" in the local part)
   *   @example.com (Missing local part)
   *   user@example (Missing TLD)
   *   user@example..com (Double dots in the domain part)
   *
   * Note: readonly because the validation regex shouldn't change without requirements
   */
  const Email =
    "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9]{2,3}$";

  return (control: AbstractControl): { [key: string]: any } | null => {
    const email = control.value;
    if (!email) {
      return null; // Return null when the email is empty
    }
    // Regular expression pattern for email validation
    const emailPattern = new RegExp(Email, 'i');
    if (!emailPattern.test(email)) {
      return { email: true };
    }
    const emailParts = email.split('@');
    if (emailParts.length !== 2) {
      return { email: true };
    }
    return null;
  };
}
