import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { confirmPasswordValidator } from 'src/app/shared/validators/confirmPasswordValidator';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  /**
   * Holds the data for current Cognito user
   */
  cognitoUser: any;
  /**
   * Holds the state for different type of views
   */
  viewType: 'login' | 'createPassword' | 'verificationCode' | 'mfaSetup' = 'login';
  /**
   * Form control for verification code
   */
  code: UntypedFormControl = new FormControl('', [Validators.required]);
  /**
   * From group for login form
   */
  login?: UntypedFormGroup;
  /**
   * From group for create password form
   */
  createPassword?: UntypedFormGroup;
  /**
   * Form control for verification code for phone verification
   */
  verifyPhoneCode: UntypedFormControl = new FormControl('', [Validators.required]);
  /**
   * Form control for phone number for MFA setup
   */
  phoneNumber: UntypedFormControl = new FormControl('', [Validators.required]);
  /**
   * Holds the value for timer
   */
  timer$?: Observable<number>;
  /**
   * Holds the value for timer count
   */
  timerValue: number = 60;
  /**
   * Indicates the state of resend code link
   */
  linkDisabled: boolean = true;
  /**
   * Subscription for timer
   */
  timerSubscription?: Subscription;
  /**
   * Indicates component is loading data
   */
  isLoading: boolean = false;
  /**
   * Indicates the value of skipMFA user attribute
   */
  skipMFA: boolean = false;

  /**
   * Constructor for {@link LoginComponent}
   * @param fb an instance of {@link FormBuilder} to interact with reactive forms
   * @param router an instance of {@link Router} to use routing features
   * @param nzMessageService an instance of {@link NzMessageService} to display user friendly message to user
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private nzMessageService: NzMessageService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.login = this.fb.group({
      email: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required]),
    });

    this.createPassword = this.fb.group(
      {
        newPassword: this.fb.control('', [Validators.required]),
        confirmPassword: this.fb.control('', [Validators.required]),
      },
      {
        validator: confirmPasswordValidator,
      },
    );
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  handleSignIn() {
    this.isLoading = true;
    const username = this.email.value;
    const password = this.password.value;
    Auth.signIn(username, password)
      .then(async (user: any) => {
        console.log('user', user);
        this.cognitoUser = user;
        this.skipMFA =
          (user?.attributes && user?.attributes['custom:skipMFA'] === 'true') ||
          (user?.challengeParam?.userAttributes && user?.challengeParam?.userAttributes['custom:skipMFA'] === 'true');

        if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.viewType = 'createPassword';
        } else if (this.skipMFA) {
          return this.navigateToHome();
        } else {
          const currentMFA = user?.challengeName || 'NOMFA';
          if (currentMFA === 'SMS_MFA') {
            this.viewType = 'verificationCode';
            this.startTimer();
          } else {
            this.viewType = 'mfaSetup';
          }
        }
      })
      .catch((err: any) => {
        console.log('err', err);
        this.viewType = 'login';
        this.nzMessageService.error(`Login failed due to ${err.message}`);
      })
      .finally(() => (this.isLoading = false));
  }

  signInOTP() {
    this.isLoading = true;
    Auth.confirmSignIn(this.cognitoUser, this.code?.value!, 'SMS_MFA')
      .then((result) => {
        Auth.currentAuthenticatedUser()
          .then((authUser) => {
            return this.navigateToHome();
          })
          .catch((err) => {
            this.nzMessageService.error(`Error ${err}, User not Authenticated`);
          })
          .finally(() => (this.isLoading = false));
      })
      .catch((err) => {
        this.viewType = 'login';
        this.nzMessageService.error('Error while signing with auth code');
      });
  }

  completePassword() {
    this.isLoading = true;
    Auth.completeNewPassword(this.cognitoUser, this.confirmPassword.value)
      .then((user) => {
        if (this.skipMFA) {
          return this.navigateToHome();
        } else {
          this.viewType = 'mfaSetup';
        }
        return;
      })
      .catch((e) => {
        this.nzMessageService.error('Unable to create the new password');
      })
      .finally(() => (this.isLoading = false));
  }

  confirmPhoneNumber() {
    Auth.verifyUserAttributeSubmit(this.cognitoUser, 'phone_number', this.verifyPhoneCode.value)
      .then((user) => {
        Auth.setPreferredMFA(this.cognitoUser, 'SMS_MFA');
        this.navigateToHome();
      })
      .catch((e) => {
        this.nzMessageService.error('Error while confirming phone number');
      });
  }

  updateAndVerifyUserAttribute(attribute: string) {
    if (!this.cognitoUser) {
      this.nzMessageService.error('You must be logged in to update MFA details. Please log in and try again.');
      return;
    }
    Auth.updateUserAttributes(this.cognitoUser, {
      phone_number: this.phoneNumber.value,
    })
      .then((_res) => {
        this.verifyUserAttribute(attribute);
        this.startTimer();
      })
      .catch((e) => {
        this.nzMessageService.error('Error updating the user attribute');
      });
  }

  verifyUserAttribute(attribute: string) {
    Auth.verifyUserAttribute(this.cognitoUser, attribute);
  }

  startTimer() {
    this.timer$ = timer(30, 1000);
    this.timerSubscription = this.timer$.subscribe(() => {
      if (this.timerValue === 0) {
        this.stopTimer();
        this.linkDisabled = false; // Enable the link when timer completes
      } else {
        this.timerValue--;
      }
    });
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resendCode() {
    if (this.viewType == 'verificationCode') {
      // Code to resend the verification code
      this.handleSignIn();
    } else if (this.viewType == 'mfaSetup') {
      this.verifyUserAttribute('phone_number');
    }
    this.timerValue = 30; // Reset timer
    this.linkDisabled = true; // Disable the link again
    this.startTimer(); // Start the timer again
  }

  navigateToHome() {
    this.isLoading = true;
    return this.authService
      .validateLogin()
      .then(() => {
        this.router.navigate(['./home']);
      })
      .finally(() => (this.isLoading = false));
  }

  federatedSignIn() {
    Auth.federatedSignIn({
      customProvider: 'okta',
    })
      .then((creds) => {
        console.log('creds', creds);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  get email(): UntypedFormControl {
    return this.login?.get('email') as UntypedFormControl;
  }

  get password(): UntypedFormControl {
    return this.login?.get('password') as UntypedFormControl;
  }

  get confirmPassword(): UntypedFormControl {
    return this.createPassword?.get('confirmPassword') as UntypedFormControl;
  }

  get newPassword(): UntypedFormControl {
    return this.createPassword?.get('newPassword') as UntypedFormControl;
  }
}
