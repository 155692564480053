import { Component } from "@angular/core";

@Component({
  selector: "app-solution-center",
  templateUrl: "./solution-center.component.html",
  styleUrls: ["./solution-center.component.scss"],
})
export class SolutionCenterComponent {
  /**
   * Title/banner text
   */
  bannerText: string = "Solution Center";
  /**
   * List of key features
   */
  keyFeature: any[] = [
    {
      title: "Interoperability",
      description:
        "Effortlessly integrates data from diverse cloud platforms for seamless collaboration",
      imagePath: "/assets/images/interoperability.jpg",
    },
    {
      title: "Security",
      description:
        "Strict data isolation measures and encryption to safeguard integrity and confidentiality",
      imagePath: "/assets/images/security.jpg",
    },
    {
      title: "Privacy-safe",
      description:
        "Protect sensitive information through data anonymization & restricted querying operations",
      imagePath: "/assets/images/privacy-safe.jpg",
    },
    {
      title: "Automation",
      description:
        "Faster time to insights with automated processed and workflows",
      imagePath: "/assets/images/automation.jpg",
    },
    {
      title: "Governance",
      description:
        "Fine-grained data access with strict data disposal measures for risk minimization",
      imagePath: "/assets/images/governance.jpg",
    },
    {
      title: "Trust",
      description:
        "Enhanced transparency & confidence with comprehensive logging of data usage",
      imagePath: "/assets/images/trust.jpg",
    },
  ];
  /**
   * List of Faq's
   */
  faqs: any[] = [
    {
      active: true,
      name: "What is the NFL Data Clean Room (DCR)?",
      content:
        "The NFL Data Clean Room is a secure environment where the NFL processes and analyzes anonymized data without exposing sensitive information to generate insights and reports that are shared with sponsors & partners.",
      icon: "double-right",
      customStyle: {
        color: "#013369",
      },
    },
    {
      active: false,
      name: "Who can access the NFL Data Clean Room?",
      content:
        "Currently, the portal is managed exclusively by the NFL. Sponsors do not have direct access but receive detailed outputs and insights generated from the DCR.",
      icon: "double-right",
    },
    {
      active: false,
      name: "How can sponsors benefit from the NFL Data Clean Room?",
      content:
        "Sponsors can leverage insights derived from the DCR to enhance their marketing strategies, improve audience targeting, and measure campaign effectiveness while ensuring data privacy.",
      icon: "double-right",
    },
    {
      active: false,
      name: "What measures are in place to ensure data security within the NFL DCR?",
      content:
        "The DCR employs strict security protocols to protect data, including encryption and controlled access to ensure that sensitive information is not exposed during analysis.",
      icon: "double-right",
    },
    {
      active: false,
      name: "How can sponsors get started with the Data Clean Room?",
      content:
        "Sponsors interested in utilizing the DCR should contact their NFL representative to discuss potential collaboration and access to available resources.",
      icon: "double-right",
    },
    {
      active: false,
      name: "Are there any costs associated with receiving insights from the data clean room portal?",
      content:
        "The basic insights & reports generated by the NFL DCR are typically included as part of your sponsorship agreement. For specific details, please refer to your contract or contact your NFL account representative.",
      icon: "double-right",
    },
    {
      active: false,
      name: "How does the NFL DCR compare to other data clean room solutions in the market?",
      content:
        "The NFL DCR is tailored specifically for NFL sponsors, offering unique insights into NFL-related data that may not be available through other general-purpose data clean rooms",
      icon: "double-right",
    },
  ];
  /**
   * List of feature details
   */
  featureDetails: any[] = [
    {
      title: "Secure Data Sharing",
      description:
        "The NFL Data Clean Room offers a secure environment for partners to prepare and share data while maintaining the highest standards of privacy and compliance.",
      content: [
        {
          title: "Secure Data Preparation",
          desc: "Transform your datasets into anonymized values to enhance privacy and protect sensitive information.",
          linkText: "Data Preparation Tool - User Guide",
          link: "/resource-hub",
          sectionLink: "data=prep-guide",
        },
        {
          title: "Seamless Data Integration",
          desc: "Easily connect data to the Data Clean Room (DCR) through cross-account access, integrating smoothly with your existing data infrastructure.",
          linkText: "Data Sharing - User Guide",
          link: "/resource-hub",
          sectionLink: "data-sharing-guide",
        },
        {
          title: "Privacy-Preserving Analysis",
          desc: "Perform joint analysis on combined datasets within the clean room, ensuring compliance with privacy regulations such as GDPR and CCPA, without exposing sensitive information.",
        },
      ],
      imagePath: "/assets/images/secure_data_sharing.jpg",
    },
    {
      title: "Data Matching & Processing",
      description:
        "The NFL Data Clean Room provides a secure environment for data matching and processing, ensuring privacy and compliance while enabling valuable insights.",
      content: [
        {
          title: "Restricted Querying",
          desc: "Our system enforces strict controls to prevent unauthorized access to raw data. Only pre-approved, aggregated queries are allowed, ensuring that individual-level data remains protected.",
        },
        {
          title: "Comprehensive Query Logging",
          desc: "All queries executed within the clean room are meticulously logged, providing a complete audit trail for compliance and security purposes.",
        },
        {
          title: "Data Residency and Purge",
          desc: "We adhere to data residency requirements by storing data in compliant locations and implementing automatic data purging mechanisms to remove data after its intended use, minimizing long-term privacy risks.",
        },
      ],
      imagePath: "/assets/images/data_matching_processing.jpg",
    },
    {
      title: "Outputs & Data Export",
      description:
        "The NFL Data Clean Room provides robust capabilities for utilizing query results to generate insights from connected datasets and export them in structures and formats that ensure privacy expectations are met.",
      content: [
        {
          title: "Aggregate Outputs",
          desc: "Aggregate outputs offer a high-level view, identify common customers using overlap analysis, segment consumers for targeted marketing, and use existing data to find similar potential customers.",
        },
        {
          title: "Custom Analytical Outputs",
          desc: "Custom analytical reports tailored to specific business needs to enable deeper insights and more precise targeting strategies, including advanced metrics, trend analysis, and predictive modeling to support strategic decision-making.",
        },
        {
          title: "Individual-Level Outputs",
          desc: "When necessary and agreed upon, data can be exported at the individual user level for activation while maintaining privacy controls.",
        },
      ],
      imagePath: "/assets/images/output_data_export.jpg",
    },
  ];
}
