import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';
import { Auth } from 'aws-amplify';
import appConfig from '../app-config';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loginState: boolean = false;
  /**
   * Current userInfo
   */
  private userInfo?: any;
  /**
   * Pages routes that application should not save as a state param when logging out
   */
  readonly DO_NOT_SAVE_STATES = ['/not-authorized', '/invalid-account', '/notFound', '/login', '/login'];

  constructor(private cookieService: CookieService, private nzMessageService: NzMessageService) {}

  /**
   * Logs user out
   */
  logout() {
    this.loginState = false;
    Auth.signOut();
  }

  /**
   * Method to get the userinfo
   */
  fetchUserDetails() {
    return Auth.currentUserInfo()
      .then((user) => {
        this.userInfo = user;
      })
      .catch((err) => {
        this.nzMessageService.error(`${err} while getting current user info`);
      });
  }

  getUserInfo() {
    return this.userInfo;
  }

  /**
   * Getter for checking logged in user
   */
  async isLoggedIn(): Promise<boolean> {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const currentMFA = await Auth.getPreferredMFA(cognitoUser, {
        bypassCache: true,
      });

      if (currentMFA === 'NOMFA' && cognitoUser.attributes['custom:skipMFA'] == 'false') {
        return false;
      } else {
        const cookieInfo = await this.amplifyCookieInfo();
        return Boolean(localStorage.getItem(`${cookieInfo}.idToken`));
        // return Boolean(this.cookieService.getCookie(`${cookieInfo}.idToken`));
      }
    } catch (error) {
      // Return false in case of an error or if username is not available
      return false;
    }
  }

  /**
   * Getter for getting Amplify Cookie Basic info
   */
  async amplifyCookieInfo(): Promise<string> {
    const userInfo = await Auth.currentUserInfo();
    return `CognitoIdentityServiceProvider.${appConfig.auth.client_id}.${userInfo?.username}`;
  }

  /**
   * Method to validate login
   * @returns Promise
   */
  validateLogin(): Promise<boolean> {
    return new Promise(async (resolve) => {
      await this.fetchUserDetails();
      const loggedIn = await this.isLoggedIn();
      if (loggedIn) {
        this.loginState = true;
      }
      return resolve(true);
    });
  }
}
