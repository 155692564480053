<div class="header-wrapper">
  <header id="header" class="cover">
    <div class="navbar">
      <div class="logo-and-menu">
        <div class="logo">
          <img nz-image nzSrc="/assets/images/NFL_Shield_rgb.png" nzDisablePreview="true" alt="NFL LOGO" />
        </div>
        <ul nz-menu nzMode="horizontal" [nzSelectable]="false">
          <li
            nz-typography
            nz-menu-item
            [routerLink]="['/']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            Home
          </li>
          <li nz-typography nz-menu-item [routerLink]="['/solution-center']" routerLinkActive="active">
            Solution Center
          </li>
          <li nz-typography nz-menu-item [routerLink]="['/resource-hub']" routerLinkActive="active">Resource Hub</li>
          <li nz-typography nz-menu-item [routerLink]="['/contact-us']" routerLinkActive="active">Contact Us</li>
        </ul>
      </div>
      <div class="user-profile">
        <span nz-icon nzType="user" nzTheme="outline"></span>
        <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          {{ userDetails?.attributes?.email }}
          <span nz-icon nzType="down"></span>
        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <!-- <li nz-menu-item>{{ userDetails?.attributes?.email }}</li>
        <li nz-menu-divider></li> -->
            <li nz-menu-item (click)="logout()">Logout</li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </header>
</div>
