import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.dev';

/**
 * Used to store cookies in the browser
 */
@Injectable({
  providedIn: 'root',
})
export class CookieService {
  /**
   * Sets the cookie with the given expiration time and the secure flag if the app is not running locally
   * @param name used as the key to store the cookie
   * @param value the value to store under the key
   * @param seconds the time until the cookie expires
   */
  setCookie(name: any, value: any, seconds: any) {
    let maxAge = '';
    if (seconds) {
      maxAge = `; Max-Age=${seconds}`;
    }
    document.cookie = `${name}=${value || ''}${maxAge}; path=/; ${environment.production ? 'secure' : ''}`;
  }

  /**
   * Used to retrieve a cookie by key
   * @param name the key for which to fetch the value
   */
  getCookie(name: any) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  /**
   * Deletes a cookie by key
   * @param name the key of the cookie to delete
   */
  eraseCookie(name: any) {
    document.cookie = `${name}=; Max-Age=-99999999;`;
  }
}
