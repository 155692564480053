<!-- Banner -->
<div class="banner">
  <div class="overlay"></div>
  <div class="banner-content">
    <h1>{{ bannerText }}</h1>
  </div>
</div>

<!-- Contact Us container -->
<div nz-row class="contact-us-container">
  <div nz-col nzSpan="10" nzOffset="2">
    <div class="left">
      <h2 nz-typography>Contact Information</h2>

      <div nz-row>
        <span nz-icon nzType="environment" nzTheme="outline" class="icon"></span>
        <p nz-typography>New York</p>
      </div>
      <!-- TODO: To remove below comment once get the phone number from business -->
      <!-- <div nz-row>
        <span nz-icon nzType="phone" nzTheme="outline" class="icon"></span>
        <p nz-typography>+1 000 000 0000</p>
      </div> -->
      <div nz-row>
        <span nz-icon nzType="mail" nzTheme="outline" class="icon"></span>
        <p nz-typography>
          <a href="mailto:dcr-admin@nfl.com">{{ adminEmail }}</a>
        </p>
      </div>
    </div>
  </div>
  <div nz-col nzSpan="12">
    <h2 nz-typography>Get In Touch</h2>
    <div class="right">
      <form nz-form [formGroup]="contactUsForm" class="login-form" *ngIf="contactUsForm">
        <div nz-row>
          <div nz-col nzSpan="11">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your first name">
                <nz-form-label class="nfl-form-label" nzNoColon="true"> First Name </nz-form-label>
                <nz-input-group nzPrefixIcon="user">
                  <input type="text" nz-input formControlName="firstName" placeholder="First Name" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12" nzOffset="1">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your last name">
                <nz-form-label class="nfl-form-label" nzNoColon="true"> Last Name </nz-form-label>
                <nz-input-group nzPrefixIcon="idcard">
                  <input type="text" nz-input formControlName="lastName" placeholder="Last Name" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input valid email address">
                <nz-form-label class="nfl-form-label" nzNoColon="true"> Email </nz-form-label>
                <nz-input-group nzPrefixIcon="mail">
                  <input type="email" nz-input formControlName="email" placeholder="Email" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your subject">
                <nz-form-label class="nfl-form-label" nzNoColon="true"> Subject </nz-form-label>
                <nz-input-group nzPrefixIcon="book">
                  <input type="text" nz-input formControlName="subject" placeholder="Subject" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <nz-form-label class="nfl-form-label" nzNoColon="true">
                  Company &nbsp;
                  <small><i>- Optional</i></small>
                </nz-form-label>
                <nz-input-group nzPrefixIcon="home">
                  <input type="text" nz-input formControlName="company" placeholder="Company" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your message">
                <nz-form-label class="nfl-form-label" nzNoColon="true"> Message </nz-form-label>
                <textarea
                  nz-input
                  placeholder="Message"
                  formControlName="message"
                  [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                ></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <button
          nz-button
          nzType="primary"
          [nzSize]="'large'"
          nzShape="round"
          [disabled]="contactUsForm.invalid"
          (click)="submitContact()"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
