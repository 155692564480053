<ng-container>
  <!-- Get in Touch -->
  <div class="margin-top3">
    <div class="get-in-touch-banner">
      <div class="content">
        <h3 nz-typography>Get In Touch Now</h3>
        <button
          nz-button
          nzType="primary"
          [nzSize]="'large'"
          nzShape="round"
          (click)="navigateToContact()"
        >
          Contact Us
        </button>
      </div>
    </div>
  </div>

  <footer class="ant-layout-footer footer">
    <div class="footer-section">
      <h3 class="ant-typography">ABOUT</h3>
      <p class="ant-typography">
        The NFL Data Clean Room aims to support NFL sponsors and partners in
        their marketing initiatives by providing a regularized and
        privacy-compliant data sharing model. This enables them to harness the
        power of a robust NFL first-party dataset for enhanced audience
        targeting, unlocking valuable insights while protecting privacy.
      </p>
    </div>
    <div class="footer-section">
      <h3 class="ant-typography">ADDRESS</h3>
      <p>345 Park Avenue, 5th Floor, New York, NY 10154</p>
      <p>+1 (800) 635-5300</p>
      <p><a href="mailto:dcr-admin@nfl.com"> dcr-admin@nfl.com</a></p>
    </div>
    <div class="footer-section">
      <h3 class="ant-typography">QUICK LINKS</h3>
      <ul class="ant-list ant-list-split">
        <li class="ant-list-item" [routerLink]="['/resource-hub']">
          User Guide
        </li>
        <li
          class="ant-list-item"
          [routerLink]="['/solution-center']"
          fragment="question"
        >
          FAQ's
        </li>
        <li class="ant-list-item">Privacy Policy</li>
      </ul>
    </div>
  </footer>
  <div class="footer-bottom ant-layout-footer">
    <p class="ant-typography">
      Copyright © 2024 All Rights Reserved by NFL Enterprises LLC.
    </p>
  </div>
</ng-container>
