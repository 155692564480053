<div nz-row class="container">
  <div nz-col nzSpan="12" nzOffset="6">
    <nz-card>
      <h2 nz-typography>Unauthorized Action</h2>
      <p nz-typography>You do not have permission to access this page.</p>
      <p nz-typography>Please contact the admin for access.</p>
      <button nz-button nzType="primary" (click)="returnToHome()">Return to Home</button>
    </nz-card>
  </div>
</div>
