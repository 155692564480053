import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-hashed-file-movement',
  templateUrl: './hashed-file-movement.component.html',
  styleUrls: ['./hashed-file-movement.component.scss'],
})
export class HashedFileMovementComponent {
  /**
   * Holds the list of the file
   */
  list: any;

  /**
   * Constructor for {@link HashedFileMovementComponent}
   * @param nzMessageService an instance of {@link NzMessageService} used to display user friendly message to user
   * @param router an instance of {@link Router} to use navigation methods
   * @param activatedRoute an instance of {@link ActivatedRoute} to get the active parent of the route
   */
  constructor(
    private nzMessageService: NzMessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  /**
   * Event Emitter to perform action based on upload file result
   * @param event Event that let the user navigate to list on successful upload of the files
   */
  uploadResult(event: any): void {
    if (event == 'success') {
      this.nzMessageService.success('File(s) uploaded successfully.');

      this.router.navigate(['list'], { relativeTo: this.activatedRoute.parent });
    } else {
      this.nzMessageService.error(`File(s) upload failed with error ${event}`);
    }
  }
}
