<div class="app-nav">
  <div class="logo">
    <img nz-image nzSrc="/assets/images/NFL_Shield_rgb.png" nzDisablePreview="true" alt="NFL LOGO" (click)="home()" />
    <ul nz-menu nzMode="horizontal" [nzSelectable]="false">
      <li
        nz-typography
        nz-menu-item
        [routerLink]="['/exporter-tool']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Export to S3
      </li>
      <li
        nz-typography
        nz-menu-item
        [routerLink]="['/exporter-tool/list']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        File History
      </li>
    </ul>
  </div>

  <div class="user-profile">
    <span nz-icon nzType="user" nzTheme="outline"></span>
    <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
      {{ userDetails?.attributes?.email }}
      <span nz-icon nzType="down"></span>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <!-- <li nz-menu-item>{{ userDetails?.attributes?.email }}</li>
        <li nz-menu-divider></li> -->
        <li nz-menu-item (click)="logout()">Logout</li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
