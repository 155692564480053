import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-external-layout",
  templateUrl: "./external-layout.component.html",
  styleUrls: ["./external-layout.component.scss"],
})
export class ExternalLayoutComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    // Access the full route URL
  }
}
