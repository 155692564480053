import { Injectable } from "@angular/core";
import * as pdfjsLib from "pdfjs-dist";

@Injectable({
  providedIn: "root",
})
export class PdfExtractorService {
  /**
   * Constructor for {@link PdfExtractorService}
   */
  constructor() {
    // Set the worker source
    (
      pdfjsLib as any
    ).GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
      (pdfjsLib as any).version
    }/pdf.worker.js`;
  }

  /**
   * Method to load the text from the pdf
   * @param pdfUrl Url where pdf is stored
   * @returns Promise with text/content from the pdf
   */
  async getPdfText(pdfUrl: string): Promise<string> {
    const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
    const firstPage = await pdf.getPage(1);
    console.log("firstPage", firstPage);
    const textContent = await firstPage.getTextContent();
    let text = "";
    textContent.items.forEach((item: any) => {
      text += item.str + " ";
    });
    return text;
  }
}
