<nz-spin nzTip="Loading..." [nzSize]="'large'" [nzSpinning]="isLoading">
  <div class="file-movement">
    <div class="title">
      <h2>Uploaded Files on S3</h2>
    </div>
    <div class="content">
      <app-data-table [rows]="fileList"></app-data-table>
    </div>
  </div>
</nz-spin>
