<div nz-row>
  <div nz-col [nzSpan]="colSpan" [nzOffset]="offset ? offset : 0">
    <nz-card [nzTitle]="title" [nzExtra]="extraTemplate">
      <p nz-typography>{{ content || pdfText }}</p>
      <div style="text-align: center"></div>
    </nz-card>
  </div>
</div>

<ng-template #extraTemplate>
  <span
    nz-icon
    nzType="file-pdf"
    nzTheme="outline"
    class="icon"
    (click)="openPdf()"
    [nz-tooltip]="'Preview Pdf'"
  >
  </span>
  <span
    nz-icon
    nzType="download"
    nzTheme="outline"
    class="margin-left1 icon"
    (click)="downloadPdf()"
    [nz-tooltip]="'Download'"
  >
  </span>
</ng-template>
