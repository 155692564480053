import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Returns true if user is logged in
   */
  public loginState: boolean = false;
  /**
   * Holds the error state while doing Federation signIn,; Stores error from Auth, cognito and OAuth
   */
  public errorState: { message: string } | null = null;
  /**
   * Current userInfo
   */
  private userInfo?: any;

  constructor(private nzMessageService: NzMessageService) {}

  /**
   * Logs user out
   */
  logout() {
    this.loginState = false;
    Auth.signOut();
  }

  /**
   * Method to get the userinfo
   */
  fetchUserDetails(): Promise<any> {
    return Auth.currentUserInfo()
      .then((user) => {
        this.userInfo = user;
      })
      .catch((err) => {
        this.nzMessageService.error(`${err} while getting current user info`);
      });
  }

  /**
   * Utility method to get the user details
   * @returns User Object
   */
  async getUserInfo(): Promise<any> {
    await this.fetchUserDetails();
    return this.userInfo;
  }

  /**
   * Method to validate login
   * @returns Promise
   */
  async validateLogin(): Promise<boolean> {
    try {
      await Auth.currentSession();
      this.loginState = true;
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Method for Federated sign in via okta
   */
  public federatedOktaSignIn() {
    Auth.federatedSignIn({
      customProvider: 'Okta',
      customState: '/',
    }).catch((err) => {
      console.log('err', err);
    });
  }
}
