<!-- Banner -->
<div class="banner">
  <div class="overlay"></div>
  <div class="banner-content">
    <h1>{{ bannerTitle }}</h1>
    <h2>{{ bannerSubtitle }}</h2>
  </div>
</div>

<!-- Marketing Insights -->
<div nz-row class="marketing-insights">
  <h2 nz-typography>Unlock Marketing Potential with NFL DCR</h2>
  <p nz-typography>
    Enhance sponsor engagement with our data clean room infrastructure, offering
    tools for audience insights, campaign optimization, advanced segmentation,
    and product insights.
  </p>
  <div
    nz-row
    [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"
    class="margin-top2"
  >
    <div
      nz-col
      class="gutter-row"
      nzXs="12"
      nzSm="12"
      nzMd="12"
      nzLg="6"
      *ngFor="let item of insights; index as i; first as isFirst"
    >
      <div class="inner-box">
        <span
          nz-icon
          [nzType]="item.icon"
          nzTheme="outline"
          class="icon"
        ></span>
        <h3 nz-typography>{{ item.title }}</h3>
        <p nz-typography>{{ item.description }}</p>
      </div>
    </div>
  </div>
</div>

<!-- Fan Data Overview -->
<div nz-row class="fan-data">
  <h2 nz-typography>Harness the potential of NFL data</h2>
  <div nz-row class="data-overview">
    <div
      nz-col
      *ngFor="let data of fanDataAttributes; index as i; last as isLast"
      [nzSpan]="isLast ? 24 : 8"
    >
      <div class="section" [ngClass]="{ 'min-height': !isLast }">
        <span
          nz-icon
          [nzType]="data.icon"
          nzTheme="outline"
          class="icon"
        ></span>
        <h3 nz-typography>{{ data.title }}</h3>
        <p nz-typography>
          {{ data.desc }}
        </p>
      </div>
    </div>
  </div>
  <div nz-row></div>
</div>

<!-- DCR Analytics capabilities - Section 2 -->
<div nz-row class="capabilities">
  <h2 nz-typography>DCR Analytics Capabilities</h2>
  <nz-carousel nzAutoPlay [nzEffect]="'fade'" [nzAutoPlaySpeed]="4000">
    <div
      nz-carousel-content
      class="use-cases"
      *ngFor="let usecase of nestedUseCases; index as i; first as isFirst"
    >
      <div nz-row class="card-wrapper">
        <div
          nz-col
          [nzXs]="{ span: 7, offset: 1 }"
          [nzLg]="
            isFirstJ || j == 3 ? { span: 6, offset: 1 } : { span: 6, offset: 2 }
          "
          *ngFor="let cardItem of usecase.items; index as j; first as isFirstJ"
        >
          <nz-card
            nzHoverable
            [nzCover]="coverTemplate"
            [ngClass]="{ 'highlighted-card': j == 1, 'side-card': j != 1 }"
          >
            <nz-card-meta
              [nzTitle]="cardItem.title"
              [nzDescription]="cardItem.description"
            ></nz-card-meta>
          </nz-card>
          <ng-template #coverTemplate>
            <img alt="example" [src]="cardItem.imagePath" />
          </ng-template>
        </div>
      </div>
    </div>
  </nz-carousel>
</div>

<div class="dcr-process">
  <h2 nz-typography class="text-center">
    NFL's approach to collaborative intelligence
  </h2>
  <div nz-row class="grid">
    <div
      nz-col
      [nzSpan]="10"
      [nzOffset]="isFirst || i == 2 ? 1 : 1"
      *ngFor="let item of dcrProcess; index as i; first as isFirst"
    >
      <nz-card class="step">
        <div class="step-number">
          <div class="step-number-bg"></div>
          <div class="step-number-count">{{ i + 1 }}</div>
        </div>
        <h3 nz-typography class="step-title">{{ item.title }}</h3>
        <p nz-typography class="step-description">
          {{ item.description }}
        </p>
        <span
          nz-icon
          [nzType]="item.icon"
          nzTheme="outline"
          class="icon"
        ></span>
      </nz-card>
    </div>
  </div>
</div>
