import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { customEmailValidator } from 'src/app/shared/validators/email-structure.validator';

type PostContactResponse = {
  PostContact: {
    submissionId: string;
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    message: string;
    timestamp: string;
  };
};

const POST_CONTACT_MUTATION = /* Graphql */ `
  mutation MyMutation($contactRequest: ContactInput!) {
    PostContact(contactRequest: $contactRequest) {
      submissionId
    }
  }
`;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  /**
   * Title/banner text
   */
  bannerText: string = 'Contact Us';
  /**
   * Form group for contact us
   */
  contactUsForm?: UntypedFormGroup;
  /**
   * Admin's email address
   */
  readonly adminEmail = 'dcr-admin@nfl.com';

  /**
   * Constructor for {@link ContactUsComponent}
   * @param fb an instance of {@link FormBuilder} to create and use reactive form
   * @param nzMessage an instance of {@link NzMessageService} to display user friendly message to users
   */
  constructor(private fb: FormBuilder, private nzMessage: NzMessageService) {}

  ngOnInit(): void {
    this.contactUsForm = this.fb.group({
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required, customEmailValidator()]),
      subject: this.fb.control('', [Validators.required]),
      message: this.fb.control('', [Validators.required]),
      company: this.fb.control(''),
    });
  }

  /**
   * Method to submit the contact details to db
   * API Integration
   */
  async submitContact(): Promise<void> {
    const requestPayload = this.contactUsForm?.value;
    API.graphql<GraphQLQuery<PostContactResponse>>({
      query: POST_CONTACT_MUTATION,
      variables: { contactRequest: requestPayload },
    })
      .then((res) => {
        this.nzMessage.success('Enquiry submitted successfully');
        this.contactUsForm?.reset();
      })
      .catch((err) => {
        this.nzMessage.error('Submission failed, please try again');
      });
  }
}
