import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Observable, interval, map, takeWhile } from "rxjs";
import { LoginComponent } from "../login/login.component";

@Component({
  selector: "app-resend-code",
  templateUrl: "./resend-code.component.html",
  styleUrls: ["./resend-code.component.scss"],
})
export class ResendCodeComponent implements OnInit {
  /**
   * Reference to LoginComponent component
   */
  @ViewChild(LoginComponent) loginComponent?: LoginComponent;
  /**
   * Indicates if code is for attribute verification
   */
  @Input() verifyAttribute: boolean = false;
  /**
   * Holds the value for attribute name to be verified
   */
  @Input() attributeName: string = "";
  /**
   * Observable for timer
   */
  timer$?: Observable<number>;
  /**
   * Holds the value for timer count
   */
  timerValue: number = 30;
  /**
   * Used to update the state of resend link
   */
  linkDisabled: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer() {
    this.timer$ = interval(1000).pipe(
      takeWhile(() => this.timerValue > 0),
      map(() => this.timerValue--)
    );

    this.timer$.subscribe(() => {
      if (this.timerValue === 0) {
        this.linkDisabled = false; // Enable the link when timer reaches 0
      }
    });
  }

  resendCode() {
    // Code to resend the verification code
    if (this.verifyAttribute) {
      this.loginComponent?.verifyUserAttribute(this.attributeName);
    } else {
      this.loginComponent?.handleSignIn();
    }
    this.timerValue = 30; // Reset timer
    this.linkDisabled = true; // Disable the link again
    this.startTimer(); // Start the timer again
  }
}
