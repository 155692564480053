<div class="table-content">
  <nz-table
    #nzTable
    nzSize="small"
    [nzData]="rows"
    nzTableLayout="auto"
    nzShowPagination
    nzShowSizeChanger
    [nzLoading]="isLoading"
    [nzSize]="'middle'"
    [nzFrontPagination]="true"
    [nzTotal]="totalCount!"
    [nzOuterBordered]="true"
    [nzPageSizeOptions]="supportedPageSizes"
    [nzNoResult]="noResultTemplate"
    [nzHideOnSinglePage]="false"
  >
    <thead>
      <tr>
        <th
          *ngFor="let cell of headers; index as i"
          [nzSortOrder]="cell.sortOrder"
          [nzSortFn]="cell.sortFn"
          [nzSortDirections]="cell.sortDirections"
          [nzFilterMultiple]="cell.filterMultiple"
          [nzFilters]="cell.listOfFilter"
          [nzFilterFn]="cell.filterFn"
        >
          {{ cell.name }}
          <nz-filter-trigger
            *ngIf="i == 1"
            [(nzVisible)]="visible"
            [nzActive]="searchValue.length > 0"
            [nzDropdownMenu]="menu"
          >
            <span nz-icon nzType="search"></span>
          </nz-filter-trigger>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of nzTable.data">
        <td>{{ data.key }}</td>
        <td>
          {{ data.lastModified | dateTime }}
        </td>
        <td>
          {{ data.size | fileSize }}
        </td>

        <!-- <td
          *ngFor="let cell of headers"
          class="text-overflow"
          nz-tooltip
          [nzTooltipTitle]="data[cell]"
        >
          {{ data[cell] || "--" }} 
        </td> -->
      </tr>
    </tbody>
  </nz-table>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input
        type="text"
        nz-input
        placeholder="Search name"
        [(ngModel)]="searchValue"
      />
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        (click)="search()"
        class="search-button"
      >
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>

<ng-template #noResultTemplate let-name>
  <div style="text-align: center">
    <span
      nz-icon
      nzType="file-sync"
      nzTheme="outline"
      style="font-size: 30px"
    ></span>
    <p>No records available</p>
  </div>
</ng-template>
