import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-outer-header',
  templateUrl: './outer-header.component.html',
  styleUrls: ['./outer-header.component.scss'],
})
export class OuterHeaderComponent implements OnInit {
  userDetails: any;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.getUserDetails();
  }

  async getUserDetails() {
    this.userDetails = await this.authService.getUserInfo();
  }

  logout() {
    Auth.signOut();
    this.router.navigate(['./login']);
  }

  home() {
    this.router.navigate(['./home']);
  }
}
