// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  amplifyEnv: 'dev',
  domain: 'https://dcr-portal-dev.dna.nfl.net',
  auth: {
    hostname: 'https://nfl-dcr-dev-login.auth.us-east-1.amazoncognito.com',
    redirect_uri: 'https://dcr-portal-dev.dna.nfl.net/',
    response_type: 'code',
    client_id: '60428he37kfrcb18okoehak7uq',
    scope: ['email', 'openid', 'profile'],
    user_pool_id: 'us-east-1_VErGgB1kF',
    identity_pool_id: 'us-east-1:3ce2ae57-c895-41d7-a515-b5b41e98ced2',
    domain: 'dcr-portal-dev.dna.nfl.net',
    nflDomain: 'nfl-dcrportal-dev-pool.auth.us-east-1.amazoncognito.com',
  },
  api: {
    aws_appsync_graphqlEndpoint: 'https://d32rm7npwo1peo.cloudfront.net/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
