import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AuthGuard } from './services/auth-guard.service';
import { ReportIssueComponent } from './components/report-issue/report-issue.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { ExternalLayoutComponent } from './components/external-layout/external-layout.component';
import { HomeComponent } from './components/home/home.component';
import { SolutionCenterComponent } from './components/solution-center/solution-center.component';
import { ResourceHubComponent } from './components/resource-hub/resource-hub.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: ExternalLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'solution-center',
        component: SolutionCenterComponent,
      },
      {
        path: 'resource-hub',
        component: ResourceHubComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'exporter-tool',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: WelcomeComponent,
      },
      {
        path: 'report-issue',
        component: ReportIssueComponent,
      },
      {
        path: 'list',
        component: FileListComponent,
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
