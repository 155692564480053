<div class="background">
  <div nz-row class="login-wrapper">
    <nz-card
      style="width: 500px; margin: auto"
      [nzCover]="coverTemplate"
      nzHoverable="false"
    >
      <ng-container [ngSwitch]="viewType">
        <h2 class="logo-title">NFL DCR Portal Login</h2>
        <ng-container *ngSwitchCase="'login'">
          <form [formGroup]="login!" nz-form [nzNoColon]="true">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your email!">
                <nz-form-label class="nfl-form-label"> Email </nz-form-label>
                <nz-input-group nzPrefixIcon="user" nzSize="large">
                  <input
                    type="text"
                    nz-input
                    placeholder="Enter email"
                    formControlName="email"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Password!">
                <nz-form-label class="nfl-form-label"> Password </nz-form-label>
                <nz-input-group nzPrefixIcon="lock" nzSize="large">
                  <input
                    type="password"
                    nz-input
                    placeholder="Enter password"
                    formControlName="password"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <button
              nz-button
              class="login-form-button login-form-margin"
              (click)="handleSignIn()"
              [nzType]="'primary'"
              [disabled]="login?.invalid"
              [nzLoading]="isLoading"
            >
              Login
            </button>

            <!-- <button
              nz-button
              class="login-form-button login-form-margin"
              (click)="federatedSignIn()"
              [nzType]="'primary'"
              [disabled]="login?.invalid"
              [nzLoading]="isLoading"
            >
              Login with okta
            </button> -->
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="'verificationCode'">
          <form nz-form [nzNoColon]="true">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your username!">
                <nz-form-label class="nfl-form-label">
                  Enter Verification Code
                </nz-form-label>
                <nz-input-group nzPrefixIcon="hourglass" nzSize="large">
                  <input
                    type="text"
                    nz-input
                    placeholder="Enter verification code"
                    [formControl]="code"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <div class="resend-code-link">
              <span> Didn't receive the code yet? </span>
              <a
                nz-button
                nzType="link"
                [disabled]="linkDisabled"
                (click)="resendCode()"
              >
                Resend Code</a
              >
              <span *ngIf="timerValue > 0">{{ timerValue }}</span>
            </div>

            <button
              nz-button
              class="login-form-button login-form-margin"
              [nzType]="'primary'"
              (click)="signInOTP()"
              [disabled]="code?.invalid"
              [nzLoading]="isLoading"
            >
              Submit OTP
            </button>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="'createPassword'">
          <form [formGroup]="createPassword!" nz-form [nzNoColon]="true">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Password!">
                <nz-form-label class="nfl-form-label">
                  Please enter new password
                </nz-form-label>
                <nz-input-group nzPrefixIcon="lock" nzSize="large">
                  <input
                    type="password"
                    nz-input
                    placeholder="Enter new password"
                    formControlName="newPassword"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control
                nzErrorTip="Please re-enter your password to confirm!"
              >
                <nz-form-label class="nfl-form-label">
                  Please confirm new password
                </nz-form-label>
                <nz-input-group nzPrefixIcon="lock" nzSize="large">
                  <input
                    type="password"
                    nz-input
                    placeholder="Confirm new password"
                    formControlName="confirmPassword"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-label
              *ngIf="createPassword?.errors && createPassword?.touched"
              class="custom-error"
              >Confirm New Password should match with New Password
            </nz-form-label>
            <button
              nz-button
              class="login-form-button login-form-margin"
              [nzType]="'primary'"
              [disabled]="createPassword?.invalid"
              [nzLoading]="isLoading"
              (click)="completePassword()"
            >
              Confirm and Proceed
            </button>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="'mfaSetup'">
          <form nz-form [nzNoColon]="true">
            <div class="phone-verification-wrapper">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input your mobile number">
                  <nz-form-label class="nfl-form-label">
                    Please enter your mobile number for MFA
                  </nz-form-label>
                  <nz-input-group nzPrefixIcon="mobile" nzSize="large">
                    <input
                      type="text"
                      nz-input
                      placeholder="+1 000 000 0000"
                      [formControl]="phoneNumber"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <button
                nz-button
                class="verify-btn"
                [nzType]="'primary'"
                [disabled]="!!phoneNumber?.invalid"
                (click)="updateAndVerifyUserAttribute('phone_number')"
                nzValue="large"
                nzSize="large"
              >
                Verify
              </button>
            </div>

            <nz-form-item>
              <nz-form-control
                nzErrorTip="Please input your authentication code"
              >
                <nz-form-label class="nfl-form-label">
                  Enter the authentication code that received on entered mobile
                  number
                </nz-form-label>
                <nz-input-group nzPrefixIcon="mobile" nzSize="large">
                  <input
                    type="text"
                    nz-input
                    placeholder="Enter authentication code"
                    [formControl]="verifyPhoneCode"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <div class="resend-code-link" *ngIf="timerValue < 60">
              <span> Didn't receive the code yet? </span>
              <a
                nz-button
                nzType="link"
                [disabled]="linkDisabled"
                (click)="verifyUserAttribute('phone_number')"
              >
                Resend Code
              </a>
              <span *ngIf="timerValue > 0">{{ timerValue }}</span>
            </div>
            <button
              nz-button
              class="login-form-button login-form-margin"
              [nzType]="'primary'"
              (click)="confirmPhoneNumber()"
              [disabled]="verifyPhoneCode?.invalid"
            >
              Confirm and Login
            </button>
          </form>
        </ng-container>
      </ng-container>
    </nz-card>
  </div>
</div>

<ng-template #coverTemplate>
  <div class="logo">
    <img nz-image nzSrc="/assets/images/NFL_Shield_rgb.png" alt="NFL LOGO" />
  </div>
</ng-template>
