import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Decides if a route can be activated depending on the user's data entitlements.
   * If all guards return `true`, navigation will continue. If any guard returns `false`,
   * navigation will be cancelled.
   *
   * @param route Contains the information about a route associated with a component loaded in an
   * outlet at a particular moment in time
   * @param state Represents the state of the router at a moment in time
   * @return An observable indicating if the route is able to be activated.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const loginState = this.authService.loginState;
    if (!loginState) {
      // user is not logged in, redirect to login
      this.router.navigate(["/login"]);
    }
    return loginState;
  }
}
