import { Component, Input, OnInit } from "@angular/core";
import "pdfjs-dist/web/pdf_viewer.css";
import { PdfExtractorService } from "../../services/pdf-extractor.service";
@Component({
  selector: "app-document-card",
  templateUrl: "./document-card.component.html",
  styleUrls: ["./document-card.component.scss"],
})
export class DocumentCardComponent implements OnInit {
  /**
   * Column span length for card
   */
  @Input() colSpan: number = 8;
  /**
   * Column span length for card
   */
  @Input() offset?: number;
  /**
   * Title for the document
   */
  @Input() title: string = "Document title";
  /**
   * Content for the document
   */
  @Input() content?: string;
  /**
   * local path for pdf which we need to render
   */
  @Input() pdfUrl?: string;
  /**
   * Text extracted from pdf
   */
  pdfText: string = "";

  /**
   * Constructor for {@link DocumentCardComponent}
   * @param pdfExtractorService as instance of {@link PdfExtractorService} to read the pdf
   */
  constructor(private pdfExtractorService: PdfExtractorService) {}

  ngOnInit(): void {
    //TODO: Uncomment below method to extract text from the pdf
    //this.loadPdfText();
  }

  /**
   * Method to extract the text from pdf
   */
  async loadPdfText(): Promise<void> {
    this.pdfText = await this.pdfExtractorService.getPdfText(this.pdfUrl!);
  }

  /**
   * Utility method to preview the pdf in new window
   */
  openPdf(): void {
    const newWindow = window.open();
    newWindow?.document.write(`
      <html>
        <head><title>PDF Preview</title></head>
        <body>
          <object data="${this.pdfUrl}" type="application/pdf" width="100%" height="100%">
            <p>Your browser does not support PDFs. Please download the PDF to view it: <a href="${this.pdfUrl}">Download PDF</a>.</p>
          </object>
        </body>
      </html>
    `);
  }

  /**
   * Method to download pdf
   */
  downloadPdf(): void {
    const link = document.createElement("a");
    link.href = this.pdfUrl!;
    link.download = this.getFileNameFromUrl(this.pdfUrl!);
    link.click();
  }

  /**
   * Method to get the file name
   * @param url Takes the local url for pdf
   * @returns file name
   */
  getFileNameFromUrl(url: string): string {
    return url.substring(url.lastIndexOf("/") + 1);
  }
}
