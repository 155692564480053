import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Decides if a route can be activated depending on the user's data entitlements.
   * If all guards return `true`, navigation will continue. If any guard returns `false`,
   * navigation will be cancelled.
   *
   * @return An observable indicating if the route is able to be activated.
   */
  canActivate(): Promise<boolean> | boolean {
    const loginState = this.authService.loginState;
    if (!loginState) {
      // user is not logged in, redirect to login
      this.authService.federatedOktaSignIn();
    }
    return loginState;
  }
}
