import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss'],
})
export class ReportIssueComponent implements OnInit {
  userDetails: any;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.getUserDetails();
  }

  async getUserDetails() {
    this.userDetails = await this.authService.getUserInfo();
  }
}
