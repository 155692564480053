<div class="file-movement">
  <div class="title">
    <h2>Need Help ? Report the issue here</h2>
  </div>
  <div class="content">
    <app-file-upload
      [labelText]="'Select log file for today`s date'"
      [hintLabel]="'Hint: Your Directory/backend/bin/log/{today-date}.txt'"
      [isReportIssue]="true"
      [allowedExtensions]="['.txt']"
      [userDetails]="userDetails"
    ></app-file-upload>
  </div>
</div>
