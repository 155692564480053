<div>
  <form nz-form [nzNoColon]="true">
    <nz-form-label class="nfl-form-label">
      {{ labelText }}
    </nz-form-label>
    <div *ngIf="hintLabel">
      <small
        ><i>{{ hintLabel }}</i>
      </small>
    </div>

    <div class="input-wrapper">
      <nz-spin [nzSpinning]="isSpinning">
        <nz-upload
          nzType="drag"
          [nzMultiple]="true"
          [(nzFileList)]="fileList"
          [nzBeforeUpload]="beforeUpload"
          [nzAccept]="allowedExtensions.join(',')"
          nzListType="picture"
          class="upload"
        >
          <p class="upload-drag-icon">
            <span
              nz-icon
              nzType="cloud-upload"
              class="ant-upload-drag-icon"
            ></span>
          </p>
          <p class="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p class="ant-upload-hint">Support for a single or bulk upload</p>
        </nz-upload>
      </nz-spin>
      <nz-progress
        *ngIf="progressRatio > 0"
        [nzPercent]="progressRatio"
      ></nz-progress>
      <button
        nz-button
        [nzType]="'primary'"
        [nzLoading]="uploading"
        (click)="handleUpload()"
        [disabled]="fileList.length === 0"
        class="upload-btn"
      >
        {{ uploading ? "Uploading" : "Export File(s)" }}
      </button>
    </div>
  </form>
</div>
