import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  /**
   * Content for dcr process
   */
  dcrProcess: any[] = [
    {
      title: 'Secure Data Connection',
      description:
        'Establish secure connections to your data with minimal data movement. Our platform ensures utmost security and compliance while keeping your data in place.',
      imagePath: '',
      icon: 'lock',
    },
    {
      title: 'Privacy-Preserving Matching & Analysis',
      description:
        'Securely match and analyze overlapping data sets with valuable fan data. Uncover valuable insights while maintaining strict privacy controls and governance.',
      imagePath: '',
      icon: 'usergroup-add',
    },
    {
      title: 'Advanced Analytics & Insights',
      description:
        'Collaborate on analysis to discover hidden trends and generate actionable insights. Unlock access to highly marketable custom segments to drive business growth.',
      imagePath: '',
      icon: 'line-chart',
    },
    {
      title: 'Privacy-safe Activation',
      description:
        'Transform insights into action with our privacy-preserving activation capabilities. Optimize your marketing strategies and improve customer experiences without compromising data integrity.',
      imagePath: '',
      icon: 'check-circle',
    },
  ];
  /**
   * Nested content for card style carousel
   */
  nestedUseCases: any[] = [
    {
      items: [
        {
          title: 'Segmentation & Overlap Analysis',
          description:
            'Gain deep insights into your customer base by gaining access to high-value audiences and analyzing overlaps to enhance targeting strategies.',
          imagePath: '/assets/images/advance_segmentation.jpg',
        },
        {
          title: 'Lookalike & Fan Acquisition',
          description:
            'Leverage lookalike modeling to identify and acquire new customers who resemble your best customers, driving growth through precise targeting.',
          imagePath: '/assets/images/lookalike_model.jpg',
        },
        {
          title: 'Product Analytics',
          description:
            'Utilize comprehensive product analytics to uncover usage patterns, enabling you to refine and enhance your product lineup for better customer satisfaction.',
          imagePath: '/assets/images/product_analytics.jpg',
        },
      ],
    },
    {
      items: [
        {
          title: 'Lookalike & Fan Acquisition',
          description:
            'Leverage lookalike modeling to identify and acquire new customers who resemble your best customers, driving growth through precise targeting.',
          imagePath: '/assets/images/lookalike_model.jpg',
        },
        {
          title: 'Product Analytics',
          description:
            'Utilize comprehensive product analytics to uncover usage patterns, enabling you to refine and enhance your product lineup for better customer satisfaction.',
          imagePath: '/assets/images/product_analytics.jpg',
        },
        {
          title: 'Market Expansion Opportunities',
          description:
            'Explore untapped markets and forge strategic partnerships to expand your reach and achieve shared business goals.',
          imagePath: '/assets/images/market_and_collaboration.jpg',
        },
      ],
    },
    {
      items: [
        {
          title: 'Product Analytics',
          description:
            'Utilize comprehensive product analytics to uncover usage patterns, enabling you to refine and enhance your product lineup for better customer satisfaction.',
          imagePath: '/assets/images/product_analytics.jpg',
        },
        {
          title: 'Market Expansion Opportunities',
          description:
            'Explore untapped markets and forge strategic partnerships to expand your reach and achieve shared business goals.',
          imagePath: '/assets/images/market_and_collaboration.jpg',
        },
        {
          title: 'Segmentation & Overlap Analysis',
          description:
            'Gain deep insights into your customer base by gaining access to high-value audiences and analyzing overlaps to enhance targeting strategies.',
          imagePath: '/assets/images/advance_segmentation.jpg',
        },
      ],
    },
    {
      items: [
        {
          title: 'Market Expansion Opportunities',
          description:
            'Explore untapped markets and forge strategic partnerships to expand your reach and achieve shared business goals.',
          imagePath: '/assets/images/market_and_collaboration.jpg',
        },
        {
          title: 'Segmentation & Overlap Analysis',
          description:
            'Gain deep insights into your customer base by gaining access to high-value audiences and analyzing overlaps to enhance targeting strategies.',
          imagePath: '/assets/images/advance_segmentation.jpg',
        },
        {
          title: 'Lookalikes & Fan Acquisition',
          description:
            'Leverage lookalike modeling to identify and acquire new customers who resemble your best customers, driving growth through precise targeting.',
          imagePath: '/assets/images/lookalike_model.jpg',
        },
      ],
    },
  ];
  /**
   * List of marketing insights
   */
  insights = [
    {
      title: 'Audience Insights',
      description:
        'Gain a deeper understanding of your customers with advanced overlap analysis. Discover shared interests and behaviors to tailor marketing campaigns effectively.',
      icon: 'team',
    },
    {
      title: 'Advanced Segmentation',
      description:
        'Segment your audience based on behavior, preferences, and engagement. Create targeted campaigns with personalized content for each segment, increasing relevance and ROI.',
      icon: 'partition',
    },
    {
      title: 'Product Insights',
      description:
        'Analyze interactions with your products to gain insights for optimization. Align product development with customer preferences and stay ahead of market trends.',
      icon: 'pie-chart',
    },
    {
      title: 'Compliant Data Usage',
      description:
        'Benefit from the highest standards of data privacy and security. Collaborate confidently, knowing your data is protected and compliant with regulations.',
      icon: 'safety',
    },
  ];
  /**
   * List of Fan data Attributes
   */
  fanDataAttributes: any[] = [
    {
      desc: 'Connect with fans who actively consume content across multiple platforms, fostering brand loyalty and advocacy',
      icon: 'heart',
      iconColor: 'red',
      title: 'Highly engaged & loyal fans',
    },
    {
      desc: 'Access comprehensive data on fan preferences, behaviors, and demographics to drive targeted campaigns and personalized experiences',
      icon: 'idcard',
      iconColor: '#22caf6',
      title: 'Deep Fan Intelligence',
    },
    {
      desc: 'Capitalize on tech-savvy NFL enthusiasts frequent online interactions, creating prime opportunities for digital marketing and e-commerce initiatives',
      icon: 'global',
      iconColor: 'green',
      title: 'Digital-First Fan Interaction',
    },
    {
      desc: 'Tap into a vast network of over 70 million fans, offering unparalleled reach and engagement opportunities',
      icon: 'team',
      iconColor: 'orange',
      title: 'Expansive & Diverse Fanbase',
    },
  ];
  /**
   * Holds the value for banner title text
   */
  bannerTitle: string = 'Secure Data Collaboration Hub';
  /**
   * Holds the value for banner subtitle text
   */
  bannerSubtitle: string = 'Unlock powerful customer insights in trusted, privacy-compliant Data Clean Room Solution';
}
