import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /**
   * Holds the data for current Cognito user
   */
  cognitoUser: any;
  /**
   * Error from the Cognito/Okta while Federation Sign in
   */
  errorState?: { message: string };
  /**
   * Indicates component is loading data
   */
  isLoading: boolean = false;

  /**
   * Constructor for {@link LoginComponent}
   * @param router an instance of {@link Router} to use routing features
   * @param authService an instance of {@link AuthService} to get the logged in user details
   */
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.errorState = this.authService.errorState!;

    if (this.authService.loginState) {
      this.navigateToHome();
    }
  }

  navigateToHome() {
    this.isLoading = true;
    return this.authService
      .validateLogin()
      .then(() => {
        this.router.navigate(['./']);
      })
      .finally(() => (this.isLoading = false));
  }

  federatedSignIn() {
    this.authService.federatedOktaSignIn();
  }
}
