<div class="app">
  <app-header></app-header>
  <div class="app-outlet">
    <router-outlet></router-outlet>
  </div>
  <!-- TODO: Enable it -->
  <!-- <div class="icon" routerLink="/report-issue">
    <span nz-icon nzType="question-circle" nzTheme="outline"></span>
  </div> -->
  <app-footer></app-footer>
</div>
